import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import AttributeService from "@/services/AttributeService";
import searchHelper from "@/helpers/searchHelper";

const attributeOv = {
  namespaced: true,
  state: {
    ...tableState,
    sortByField: ["totalUnmappedInputValues"],
    sortDesc: true,
    onlyShowValuesToMap: false,
    attributeSearch: "",
    attributeId: "",
    totalCounts: []
  },
  mutations: {
    ...tableMutations,
    SET_ONLYSHOWVALUESTOMAP(state, value) {
      state.onlyShowValuesToMap = value;
    },
    SET_ATTRIBUTESEARCH(state, value) {
      state.attributeSearch = value;
    },
    SET_ATTRIBUTEID(state, value) {
      state.attributeId = value;
    },
    SET_TOTALCOUNTS(state, value) {
      state.totalCounts = value;
    }
  },
  actions: {
    ...tableActions,
    // the fetchItems method must be in the "extended store" to make it work!
    fetchAttributesTotalCounts(context) {
      AttributeService.fetchAttributesTotalCounts()
        .then(json => {
          context.commit("SET_TOTALCOUNTS", json);
        })
        .catch(err => {
          context.commit("SET_ERROR", true);
          console.log(err);
        });
    },
    fetchItems(context, refresh = false) {
      //console.log(new Error().stack);
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        AttributeService.fetchAttributes(
          context.state.itemsPage,
          context.state.itemsPerPage,
          context.state.sortByField,
          context.state.sortDesc,
          context.state.search
        )
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    searchItems(context) {
      var data = [...context.state.search];

      data = searchHelper.handleSearchParam(
        data,
        "id",
        context.state.attributeId
      );

      data = searchHelper.handleSearchParam(
        data,
        "name",
        context.state.attributeSearch
      );

      // if onlyShowValuesToMap, then we need attributes with values to map, which are values without mappingResultInputs
      if (context.state.onlyShowValuesToMap) {
        data = searchHelper.handleSearchParam(
          data,
          "totalUnmappedInputValues[gt]",
          0
        );
      }

      // fill the search again
      context.commit("EMPTY_SEARCH");
      if (Array.isArray(data) && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          context.commit("ADD_SEARCH", {
            column: data[i].column,
            value: data[i].value
          });
        }
      }

      context.dispatch("fetchItems", true);
    },
    setOnlyShowValuesToMap(context, value) {
      context.commit("SET_ONLYSHOWVALUESTOMAP", value);
    },
    setAttributeId(context, value) {
      context.commit("SET_ATTRIBUTEID", value);
    },
    setAttributeSearch(context, value) {
      context.commit("SET_ATTRIBUTESEARCH", value);
    }
  },
  getters: {
    ...tableGetters
  }
};

export default attributeOv;
