<template>
  <v-app>
    <Navigation />

    <AppBar title="TT Mapping" :portal-url="portalUrl">
      <template v-slot:app-bar>
        <v-spacer />
        <div>
          <LocaleChanger class="mr-4" />
        </div>
        <div v-if="!$auth.loading">
          <v-avatar color="white" @click="handleAvatarClick()">
            <v-img v-if="$auth.isAuthenticated" :src="$auth.user.picture" />
            <v-img v-if="!$auth.isAuthenticated" :src="avatar" />
          </v-avatar>
          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                depressed
                v-on="on"
                color="transparent"
                min-width="30"
              >
                <v-icon small>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="!$auth.isAuthenticated" @click="login">
                <v-list-item-title>Log in</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="$auth.isAuthenticated" @click="logout">
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </AppBar>

    <v-main>
      <Message />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import EnvProvider from "jvjr-docker-env";

export default {
  name: "App",
  data: () => ({
    portalUrl: EnvProvider.value("PORTAL_URL")
  }),
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        returnTo: this.portalUrl
      });
    }
  }
};
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-data-table td {
  height: 35px !important;
}

tr.v-data-table__selected {
  color: rgba(33, 150, 243, 0.87) !important;
  background-color: rgba(33, 150, 243, 0.12) !important;
}
</style>
