import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import AttributeService from "@/services/AttributeService";
import searchHelper from "@/helpers/searchHelper";

const outputValueOverview = {
  namespaced: true,
  state: {
    ...tableState,
    mappingOutputStatus: null,
    valueSearch: "",
    search: [
      {
        column: "type",
        value: "output"
      }
    ]
  },
  mutations: {
    ...tableMutations,
    SET_VALUESEARCH(state, value) {
      state.valueSearch = value;
    },
    SET_MAPPINGOUTPUTSTATUS(state, value) {
      state.mappingOutputStatus = value;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        AttributeService.fetchValues(
          context.state.itemsPage,
          context.state.itemsPerPage,
          context.state.sortByField,
          context.state.sortDesc,
          context.state.search
        )
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    setMappingOutputStatus(context, value) {
      context.commit("SET_MAPPINGOUTPUTSTATUS", value);
    },
    setValueSearch(context, value) {
      context.commit("SET_VALUESEARCH", value);
    },
    searchItems(context) {
      var data = [...context.state.search];
      data = searchHelper.handleSearchParam(
        data,
        "content",
        context.state.valueSearch
      );

      data = searchHelper.handleSearchParam(
        data,
        "exists[mappingOutputs]",
        context.state.mappingOutputStatus
      );

      // fill the search again
      context.commit("EMPTY_SEARCH");
      if (Array.isArray(data) && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          context.commit("ADD_SEARCH", {
            column: data[i].column,
            value: data[i].value
          });
        }
      }

      context.dispatch("fetchItems", true);
    }
  },
  getters: {
    ...tableGetters
  }
};

export default outputValueOverview;
