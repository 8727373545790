<!-- created based on the default rule https://github.com/dabernathy89/vue-query-builder/blob/master/src/layouts/Bootstrap/BootstrapRule.vue 
And the tailwind example: https://dabernathy89.github.io/vue-query-builder/custom-templates.html#tailwind-example 
Created so we can bind objects to the rule
-->

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="vqb-rule card">
    <div class="form-inline">
      <label class="mr-5">{{ rule.label }}</label>

      <!-- List of operands (optional) -->
      <select
        v-if="typeof rule.operands !== 'undefined'"
        v-model="query.operand"
        class="form-control mr-2"
        disabled
      >
        <option v-for="operand in rule.operands" :key="operand">{{
          operand
        }}</option>
      </select>

      <!-- List of operators (e.g. =, !=, >, <) -->
      <select
        v-if="
          typeof rule.operators !== 'undefined' && rule.operators.length > 1
        "
        v-model="backendOperator"
        class="form-control mr-2"
      >
        <option
          v-for="operator in rule.operators"
          :key="operator.id"
          :value="operator"
          >{{ operator.name }}</option
        >
      </select>

      <!-- Basic text input -->
      <input
        v-if="rule.inputType === 'text'"
        v-model="query.value"
        class="form-control"
        type="text"
        :placeholder="labels.textInputPlaceholder"
      />

      <!-- Radio input -->
      <template v-if="rule.inputType === 'radio'">
        <div
          v-for="choice in rule.choices"
          :key="choice.value"
          class="form-check form-check-inline"
        >
          <input
            :id="
              'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value
            "
            v-model="query.value"
            :name="'depth' + depth + '-' + rule.id + '-' + index"
            type="radio"
            :value="choice.value"
            class="flex-shrink-0 mr-1 ml-0 mt-0 form-check-input"
          />
          <label
            class="flex items-center justify-center"
            :for="
              'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value
            "
            >{{ choice.label }}</label
          >
        </div>
      </template>

      <!-- Remove rule button -->
      <button
        type="button"
        class="close ml-auto"
        @click="remove"
        v-html="labels.removeRule"
      ></button>
    </div>
  </div>
</template>

<script>
import QueryBuilderRule from "vue-query-builder/dist/rule/QueryBuilderRule.umd.js";

export default {
  extends: QueryBuilderRule,
  data: () => ({
    backendValue: null,
    backendRule: null,
    backendOperator: null
  }),
  watch: {
    backendOperator: function(val) {
      this.query.operator = val.id;
    }
  },
  mounted() {
    if (
      typeof this.rule.operators !== "undefined" &&
      this.rule.operators.length > 1
    ) {
      this.backendOperator = this.rule.operators[0];
    }
  }
};
</script>
