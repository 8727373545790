const commonHelper = {
  /**
   * returns true if value is null, undefined, "", []
   * @param {*} value
   * @returns {boolean}
   */
  isEmpty(value) {
    return value == null || value.length === 0;
  }
};
export default commonHelper;
