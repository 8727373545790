<!-- created based on the default rulegroup https://github.com/dabernathy89/vue-query-builder/blob/master/src/layouts/Bootstrap/BootstrapGroup.vue 
And the tailwind example: https://dabernathy89.github.io/vue-query-builder/custom-templates.html#tailwind-example 
Created so we can bind objects to the rulegroup
-->
<template>
  <div class="vqb-group card" :class="'depth-' + depth.toString()">
    <div class="vqb-group-heading card-header">
      <div class="match-type-container form-inline">
        <div
          class="form-inline"
          v-if="query.children != null && query.children.length > 1"
        >
          <label class="mr-2" for="vqb-match-type">{{
            labels.matchType
          }}</label>
          <select
            id="vqb-match-type"
            v-model="query.logicalOperator"
            class="inline-block w-auto mr-2 h-10 px-1 py-2 leading-normal gray-500 border border-gray-300 rounded"
          >
            <option
              v-for="label in labels.matchTypes"
              :key="label.id"
              :value="label.id"
              >{{ label.label }}</option
            >
          </select>
        </div>
        <button
          v-if="depth > 1"
          type="button"
          class="close ml-auto"
          @click="remove"
          v-html="labels.removeGroup"
        ></button>
      </div>
    </div>

    <div class="vqb-group-body card-body">
      <div class="rule-actions form-inline">
        <div class="form-group">
          <select v-model="selectedRule" class="form-control mr-2">
            <option v-for="rule in rules" :key="rule.id" :value="rule">{{
              rule.label
            }}</option>
          </select>

          <button type="button" class="btn btn-secondary mr-2" @click="addRule">
            {{ labels.addRule }}
          </button>

          <button
            v-if="depth < maxDepth"
            type="button"
            class="btn btn-secondary"
            @click="addGroup"
          >
            {{ labels.addGroup }}
          </button>
        </div>
      </div>

      <query-builder-children v-bind="$props" />
    </div>
  </div>
</template>

<script>
import QueryBuilderGroup from "vue-query-builder/dist/group/QueryBuilderGroup.umd.js";
import QueryBuilderRule from "./CustomQueryBuilderRule.vue";

export default {
  name: "QueryBuilderGroup",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    QueryBuilderRule: QueryBuilderRule
  },
  data: () => ({
    children: ""
  }),
  extends: QueryBuilderGroup
};
</script>

<style>
.vue-query-builder .vqb-group.depth-1 .vqb-rule,
.vue-query-builder .vqb-group.depth-2 {
  border-left: 2px solid #8bc34a;
}

.vue-query-builder .vqb-group.depth-2 .vqb-rule,
.vue-query-builder .vqb-group.depth-3 {
  border-left: 2px solid #00bcd4;
}

.vue-query-builder .vqb-group.depth-3 .vqb-rule,
.vue-query-builder .vqb-group.depth-4 {
  border-left: 2px solid #ff5722;
}

.vue-query-builder .close {
  opacity: 1;
  color: rgb(150, 150, 150);
}

@media (min-width: 768px) {
  .vue-query-builder .vqb-rule.form-inline .form-group {
    display: block;
  }
}
</style>
