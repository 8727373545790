import BaseService from "./BaseService";

export default class MappingService extends BaseService {
  /**
   * Runs query for given inputValues.
   * Returns a JSON with key value pairs of the inputValueId and the dryrun result for that value
   * @param {*} query
   * @param {*} inputValues
   * @returns
   */
  static async executeDryRun(query, inputValues) {
    this.initApiClientIfNotSet();

    if (inputValues.length === 0) return;

    var url = `/query/${query.id}/dryrun?`;
    for (var value of inputValues) {
      url += `&values[]=${value.id}`;
    }

    const { data } = await this.apiClient.get(url);
    return data;
  }

  /**
   * Creates query in backend based on the data from rulebuilder
   * @param {*} ruleBuilderOutput create entities based on the rule builder output
   * @param {*} attributeId needed to create values
   * @returns query object/entity
   */
  static async createQuery(ruleBuilderOutput, attributeId, mappingId) {
    let query = {
      operator: `/operators/${ruleBuilderOutput.logicalOperator}`,
      mapping: `mappings/${mappingId}`
    };
    query = await this.addQuery(query);

    // create a main rulegroup for rules without group (rules can be added directly in the query builder)
    if (ruleBuilderOutput.children.some(x => x.type === "query-builder-rule")) {
      var mainRuleGroup = await this.createRuleGroup(
        ruleBuilderOutput.logicalOperator,
        query.id
      );
    }

    for (var child of ruleBuilderOutput.children) {
      if (child.type === "query-builder-group") {
        var ruleGroup = await this.createRuleGroup(
          child.query.logicalOperator,
          query.id
        );

        // for now we only support one group depth, so this will always be a rule. When we do support more depths, then do something with recursion
        for (var nestedChild of child.query.children) {
          var ruleValue = await this.createValue(
            attributeId,
            nestedChild.query.value,
            "rule"
          );

          await this.createRule(
            ruleGroup.id,
            nestedChild.query.operator,
            ruleValue.id
          );
        }
      } else {
        var ruleValueMain = await this.createValue(
          attributeId,
          child.query.value,
          "rule"
        );

        await this.createRule(
          mainRuleGroup.id,
          child.query.operator,
          ruleValueMain.id
        );
      }
    }

    return query;
  }

  /**
   *  Creates ruleBuilder query from data of backend
   * @param {*} data
   * @returns
   */
  static async createRuleBuilderQuery(query) {
    console.log(JSON.stringify(query));
    console.log("Todo implement this");
    //TODO implement
  }

  /**
   * Creates a ruleGroup for given parameters
   * @param {*} logicalOperator
   * @param {*} queryId
   * @returns {Promise<any>}
   */
  static async createRuleGroup(logicalOperator, queryId) {
    var ruleGroup = {
      operator: `/operators/${logicalOperator}`,
      query: `/queries/${queryId}`
    };
    return this.addRuleGroup(ruleGroup);
  }

  /**
   * Creates a value for given parameters
   * @param {*} attributeId
   * @param {*} content
   * @param {*} type
   * @returns
   */
  static async createValue(attributeId, content, type) {
    var value = {
      attribute: `/attributes/${attributeId}`,
      content: content,
      type: type
    };
    return this.addValue(value);
  }

  /**
   * Creates a rule for given parameters
   * @param {*} ruleGroupId
   * @param {*} operatorId
   * @param {*} valueId
   * @returns {Promise<any>}
   */
  static async createRule(ruleGroupId, operatorId, valueId) {
    var rule = {
      ruleGroup: `/rule_groups/${ruleGroupId}`,
      operator: `/operators/${operatorId}`,
      value: `/values/${valueId}`
    };
    return this.addRule(rule);
  }

  /**
   * Fetch mapping results
   * @param page
   * @param itemsPerPage
   * @param sortByField
   * @param sortDesc
   * @param search
   * @returns {Promise<any>}
   */
  static async fetchMapping(
    page = 1,
    itemsPerPage = 10,
    sortByField = "",
    sortDesc = false,
    search = []
  ) {
    return this.fetchItems(
      "mappings",
      page,
      itemsPerPage,
      sortByField,
      sortDesc,
      search
    );
  }

  /**
   * Add mapping result
   * @param data
   * @returns {Promise<*>}
   */
  static async addMapping(data) {
    return this.addItem("mappings", data);
  }

  /**
   * Add query
   * @param data
   * @returns {Promise<*>}
   */
  static async addQuery(data) {
    return this.addItem("queries", data);
  }

  /**
   *
   * @param {*} queryId
   * @returns
   */
  static async deleteQuery(queryId) {
    return this.deleteItem("queries", queryId);
  }

  /**
   * Fetch query
   * @returns {Promise<*>}
   */
  static async fetchQuery(id) {
    return this.fetchItem("queries", id);
  }

  /**
   * Add rule group
   * @param data
   * @returns {Promise<*>}
   */
  static async addRuleGroup(data) {
    return this.addItem("rule_groups", data);
  }

  /**
   * Add rule
   * @param data
   * @returns {Promise<*>}
   */
  static async addRule(data) {
    return this.addItem("rules", data);
  }

  /**
   * Fetch mapping results
   * @param page
   * @param itemsPerPage
   * @param sortByField
   * @param sortDesc
   * @param search
   * @returns {Promise<any>}
   */
  static async fetchMappingResults(
    page = 1,
    itemsPerPage = 10,
    sortByField = "",
    sortDesc = false,
    search = []
  ) {
    return this.fetchItems(
      "mapping_results",
      page,
      itemsPerPage,
      sortByField,
      sortDesc,
      search
    );
  }

  /**
   * Add mapping result
   * @param data
   * @returns {Promise<*>}
   */
  static async addMappingResult(data) {
    return this.addItem("mapping_results", data);
  }

  /**
   * Fetch mapping results
   * @param page
   * @param itemsPerPage
   * @param sortByField
   * @param sortDesc
   * @param search
   * @returns {Promise<any>}
   */
  static async fetchValues(
    page = 1,
    itemsPerPage = 10,
    sortByField = "",
    sortDesc = false,
    search = []
  ) {
    return this.fetchItems(
      "values",
      page,
      itemsPerPage,
      sortByField,
      sortDesc,
      search
    );
  }

  /**
   * Add value
   * @param data
   * @returns {Promise<void>}
   */
  static async addValue(data) {
    return this.addItem("values", data);
  }

  /**
   * Fetch operators
   * @param page
   * @param itemsPerPage
   * @param sortByField
   * @param sortDesc
   * @param search
   * @returns {Promise<any>}
   */
  static async fetchOperators(
    page = 1,
    itemsPerPage = 10,
    sortByField = "",
    sortDesc = false,
    search = []
  ) {
    return this.fetchItems(
      "operators",
      page,
      itemsPerPage,
      sortByField,
      sortDesc,
      search
    );
  }

  /**
   * Creates a complete quickMapping
   * @returns
   * @param quickMap
   */
  static async createQuickMapping(quickMap) {
    this.initApiClientIfNotSet();

    let url = "quick_map";
    let mapping = [
      {
        name: quickMap.name,
        attribute: quickMap.attribute,
        inputValue: quickMap.inputValue,
        outputValue: quickMap.outputValue
      }
    ];
    const { data } = await this.apiClient.post(
      url,
      { inputs: mapping },
      {
        headers: { "Content-Type": "application/json" }
      }
    );
    return data;
  }
}
