import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/i18n";
import { authGuard } from "@tt/vue-auth0";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    menuTitle: i18n.t("dashboard.title"),
    menuIcon: "mdi-home-outline",
    component: () => import("@/views/Dashboard")
  },
  {
    path: "/attributes/:attribute?",
    name: "attributes",
    menuTitle: i18n.t("attribute.title"),
    menuIcon: "mdi-tag-text-outline",
    component: () => import("@/views/AttributeOverview")
  }
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeEach(authGuard);

export default router;
