import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import store from "@/store";
import i18n from "@/plugins/i18n";
import "@/plugins/auth0";
import "@/plugins/confirm";
import "@/plugins/tt";
import "@/plugins/query-builder";

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
