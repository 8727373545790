import mappingOverview from "@/store/modules/attributeOverview/mappingOverview";
import inputValueOverview from "@/store/modules/attributeOverview/inputValueOverview";
import inputValueIgnoredOverview from "@/store/modules/attributeOverview/inputValueIgnoredOverview";
import valueSyncOverview from "@/store/modules/attributeOverview/valueSyncOverview";
import attributeOv from "@/store/modules/attributeOverview/attributeOv";
import mappingCreationOverview from "@/store/modules/attributeOverview/mappingCreationOverview";
import mappingResultOverview from "@/store/modules/mappingOverview/mappingResultOverview";

const attributeOverview = {
  namespaced: true,
  modules: {
    attributeOv,
    mappingOverview,
    mappingResultOverview,
    inputValueOverview,
    inputValueIgnoredOverview,
    valueSyncOverview,
    mappingCreationOverview
  },
  state: {
    attribute: null,
    sortByField: ["name"],
    sortDesc: false
  },
  mutations: {
    SET_ATTRIBUTE(state, attribute) {
      state.attribute = attribute;
    }
  },
  actions: {
    selectAttribute(context, attribute) {
      context.commit("SET_ATTRIBUTE", attribute);

      // set inputValueOverview attribute
      context.dispatch("inputValueOverview/searchItems", true);

      // set inputValueOverview attribute
      context.dispatch("inputValueIgnoredOverview/searchItems", true);

      // set mappingResultsOverview attribute
      context.dispatch("mappingResultOverview/searchItems", true);

      // // set mappingCreationOverview attribute
      // context.dispatch("mappingCreationOverview/search");
    }
  }
};

export default attributeOverview;
