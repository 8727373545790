import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import MappingService from "@/services/MappingService";
import mappingResultOverview from "@/store/modules/mappingOverview/mappingResultOverview";

const mappingOverview = {
  namespaced: true,
  modules: {
    mappingResultOverview
  },
  state: {
    ...tableState,
    mappingResult: null,
    search: [
      {
        column: "type",
        value: "input"
      },
      {
        column: "exists[mappingResultInputs]",
        value: false
      },
      {
        column: "exists[mappingResultOutputs]",
        value: false
      }
    ]
  },
  mutations: {
    ...tableMutations,
    SET_MAPPING_RESULT(state, mappingResult) {
      state.mappingResult = mappingResult;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        MappingService.fetchValues(
          context.state.itemsPage,
          context.state.itemsPerPage,
          context.state.sortByField,
          context.state.sortDesc,
          context.state.search
        )
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    searchItems(context) {
      context.dispatch("fetchItems", true);
    },
    setMappingResult(context, mappingResult) {
      context.commit("SET_MAPPING_RESULT", mappingResult);
    }
  },
  getters: {
    ...tableGetters
  }
};

export default mappingOverview;
