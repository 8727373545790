import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import AttributeService from "@/services/AttributeService";
import searchHelper from "@/helpers/searchHelper";

const inputValueOverview = {
  namespaced: true,
  state: {
    ...tableState,
    mappedStatus: "",
    ignoredStatus: null,
    value: null,
    valueSearch: "",
    search: [
      {
        column: "type",
        value: "input"
      }
    ],
    sortByField: ["content"],
    sortDesc: false
  },
  mutations: {
    ...tableMutations,
    SET_MAPPEDSTATUS(state, value) {
      state.mappedStatus = value;
    },
    SET_IGNOREDSTATUS(state, value) {
      state.ignoredStatus = value;
    },
    SET_VALUESEARCH(state, value) {
      state.valueSearch = value;
    },
    SET_VALUE(state, value) {
      state.value = value;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        AttributeService.fetchValues(
          context.state.itemsPage,
          context.state.itemsPerPage,
          context.state.sortByField,
          context.state.sortDesc,
          context.state.search
        )
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
            console.log(context.state.search);
          });
      }
    },
    fetchValue(context, valueId) {
      AttributeService.fetchValue(valueId)
        .then(json => {
          context.commit("SET_VALUE", json);
          context.commit("SET_LOADING", false);
        })
        .catch(err => {
          context.commit("SET_ERROR", true);
          context.commit("SET_LOADING", false);
          console.log(err);
          console.log(context.state.search);
        });
    },
    setMappedStatus(context, value) {
      context.commit("SET_MAPPEDSTATUS", value);
    },
    setIgnoredStatus(context, value) {
      context.commit("SET_IGNOREDSTATUS", value);
    },
    setValueSearch(context, value) {
      context.commit("SET_VALUESEARCH", value);
    },
    setValue(context, value) {
      context.commit("SET_VALUE", value);
    },
    searchItems(context) {
      var data = [...context.state.search];

      data = searchHelper.handleSearchParam(
        data,
        "attribute",
        context.rootState.attributeOverview.attribute.id
      );

      data = searchHelper.handleSearchParam(
        data,
        "content",
        context.state.valueSearch
      );

      if (context.state.mappedStatus !== "") {
        data = searchHelper.handleSearchParam(
          data,
          "mapped",
          context.state.mappedStatus
        );
      }

      if (context.state.ignoredStatus !== null) {
        data = searchHelper.handleSearchParam(
          data,
          "ignored",
          context.state.ignoredStatus
        );
      }

      data = searchHelper.handleSearchParam(data, "type", "input");

      // fill the search again
      context.commit("EMPTY_SEARCH");
      if (Array.isArray(data) && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          context.commit("ADD_SEARCH", {
            column: data[i].column,
            value: data[i].value
          });
        }
      }

      context.dispatch("fetchItems", true);
    },
    editValue(context, value) {
      return new Promise((resolve, reject) => {
        AttributeService.editValue(value, value["id"])
          .then(() => {
            context.dispatch("fetchItems", true);
            context.dispatch(
              "attributeOverview/inputValueIgnoredOverview/fetchItems",
              true,
              { root: true }
            );
            context.dispatch("attributeOverview/attributeOv/fetchItems", true, {
              root: true
            });
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    }
  },
  getters: {
    ...tableGetters,
    valuesToMap: state => {
      return state.items.filter(item => item.mapped.length == false);
    }
  }
};

export default inputValueOverview;
