import axios from "axios";
import router from "@/router";
import { getInstance } from "@tt/vue-auth0";
import EnvProvider from "jvjr-docker-env";

export default class BaseService {
  /**
   * Sets the apiClient variable, which contains settings for the connection to the API.
   * if variable already set it does nothing
   */
  static initApiClientIfNotSet() {
    // TODO put this in a constructor if possible
    if (this.apiClient !== undefined) return;

    this.apiClient = axios.create({
      baseURL: EnvProvider.value("MAPPING_API_URL")
    });

    // JWT interceptor
    if (EnvProvider.value("ENABLE_AUTH") === "1") {
      this.apiClient.interceptors.request.use(function(config) {
        return getInstance()
          .getTokenSilently()
          .then(token => {
            config.headers["Authorization"] = "Bearer " + token;
            return config;
          });
      });
    }

    // 404 redirect
    this.apiClient.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        // not found
        if (error.response.status === 404) {
          return router.push({
            name: "404"
          });
        }

        // not authorized
        if (error.response.status === 401) {
          return router.push({
            name: "401"
          });
        }

        if (error.response.status === 405) {
          let err = {};
          err["hydra:description"] = error.response.data.detail;
          return Promise.reject(err);
        }

        return Promise.reject(error.response.data);
      }
    );
  }

  /**
   * Abstract fetch items
   * @abstract
   * @param url
   * @param page
   * @param itemsPerPage
   * @param sortByField
   * @param sortDesc
   * @param search
   * @returns {Promise<{totalItems: number, items: []}>}
   */
  static async fetchItems(
    url,
    page = 1,
    itemsPerPage = 10,
    sortByField = "",
    sortDesc = false,
    search = []
  ) {
    let response = {
      items: [],
      totalItems: 0
    };

    url += "?page=" + (page > 1 ? page : "1");
    url += "&itemsPerPage=" + itemsPerPage;
    if (sortByField !== "") {
      url += "&order[" + sortByField + "]=" + (sortDesc ? "desc" : "asc");
    }
    if (search.length > 0) {
      for (let i = 0; i < search.length; i++) {
        url += "&" + search[i].column + "=" + search[i].value;
      }
    }

    this.initApiClientIfNotSet();
    const { data } = await this.apiClient.get(url);
    response.items.length = 0; // clear
    for (let x = 0; x < data["hydra:member"].length; x++) {
      response.items.push(data["hydra:member"][x]);
    }
    response.totalItems = data["hydra:totalItems"];
    return response;
  }

  /**
   * Abstract fetch
   * @abstract
   * @param {*} url
   * @returns {Promise<{}>}
   */
  static async fetch(url) {
    this.initApiClientIfNotSet();
    const { data } = await this.apiClient.get(url);
    return data;
  }

  /**
   * Abstract fetch a single item by id
   * @abstract
   * @param {*} url
   * @param {*} id
   * @returns {Promise<{}>}
   */
  static async fetchItem(url, id) {
    url += `/${id}`;

    this.initApiClientIfNotSet();
    const { data } = await this.apiClient.get(url);
    return data;
  }

  /**
   * Abstract add item
   * @abstract
   * @param url
   * @param item
   * @returns {Promise<any>}
   */
  static async addItem(url, item) {
    this.initApiClientIfNotSet();
    const { data } = await this.apiClient.post(url, item, {
      headers: { "Content-Type": "application/json" }
    });
    return data;
  }

  /**
   * Abstract edit item
   * @abstract
   * @param url
   * @param item
   * @param id
   * @returns {Promise<any>}
   */
  static async editItem(url, item, id) {
    this.initApiClientIfNotSet();

    // build a URL
    url += "/" + id;

    const { data } = await this.apiClient.put(url, item, {
      headers: { "Content-Type": "application/json" }
    });
    return data;
  }

  /**
   * Abstract delete item
   * @abstract
   * @param url
   * @param id
   * @returns {Promise<any>}
   */
  static async deleteItem(url, id) {
    this.initApiClientIfNotSet();

    // build a URL
    url += "/" + id;

    const { data } = await this.apiClient.delete(url);
    return data;
  }

  /**
   * Abstract delete by iri
   * @abstract
   * @param url
   * @returns {Promise<any>}
   */
  static async delete(url) {
    this.initApiClientIfNotSet();

    const { data } = await this.apiClient.delete(url);
    return data;
  }
}
