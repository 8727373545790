import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import AttributeService from "@/services/AttributeService";

const mappingOverview = {
  namespaced: true,
  state: {
    ...tableState
  },
  mutations: {
    ...tableMutations
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        AttributeService.fetchMappings(
          context.state.itemsPage,
          context.state.itemsPerPage,
          context.state.sortByField,
          context.state.sortDesc,
          context.state.search
        )
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    searchItems(context) {
      context.dispatch("fetchItems", true);
    }
  },
  getters: {
    ...tableGetters
  }
};

export default mappingOverview;
