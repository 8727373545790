import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import MappingService from "@/services/MappingService";
import commonHelper from "@/helpers/commonHelper";

const ruleBuilderOverview = {
  namespaced: true,
  state: {
    ...tableState,
    sortByField: "name",
    queryBuilderQuery: {}
  },
  mutations: {
    ...tableMutations,
    SET_QUERYBUILDER_QUERY(state, value) {
      state.queryBuilderQuery = value;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      return new Promise((resolve, reject) => {
        if (refresh || context.state.items.length === 0) {
          context.commit("SET_ERROR", false);
          context.commit("SET_LOADING", true);
          MappingService.fetchOperators(
            context.state.itemsPage,
            context.state.itemsPerPage,
            context.state.sortByField,
            context.state.sortDesc,
            context.state.search
          )
            .then(json => {
              context.commit("SET_ITEMS", json);
              context.commit("SET_LOADING", false);
              resolve();
            })
            .catch(err => {
              context.commit("SET_ERROR", true);
              context.commit("SET_LOADING", false);
              console.log(err);
              reject();
            });
        }
      });
    },
    setQueryBuilderQuery(context, value) {
      context.commit("SET_QUERYBUILDER_QUERY", value);
    }
  },
  getters: {
    ...tableGetters,
    logicalOperators: state => {
      if (commonHelper.isEmpty(state.items)) return [];
      return state.items.filter(item => item.type === "logical");
    },
    comparisonOperators: state => {
      if (commonHelper.isEmpty(state.items)) return [];
      return state.items.filter(item => item.type === "comparison");
    }
  }
};

export default ruleBuilderOverview;
