import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/locales/en.json";
import nl from "@/locales/nl.json";

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.locale,
  messages: { en, nl }
});

export default i18n;
