import commonHelper from "@/helpers/commonHelper";

const searchHelper = {
  /**
   * Updates value of column when a value is supplied.
   * Deletes column if exists, when content is empty
   * Adds the column and value, when column is not found
   * Returns the (updated) searchdata array, because we can't pass the array as reference
   * @param {Array.<Object>} searchData
   * @param {string} columnName
   * @param {string} value
   * @returns {Array.<Object>} searchData
   */
  handleSearchParam(searchData, columnName, value) {
    var content = searchData.find(x => x.column === columnName);
    if (content && !commonHelper.isEmpty(value)) {
      // update value for column
      content.value = value;
    } else if (commonHelper.isEmpty(value)) {
      // remove column from search properties, if exists
      searchData = searchData.filter(x => x.column !== columnName);
    } else {
      // add column and value to search properties
      searchData.push({ column: columnName, value: value });
    }
    return searchData;
  }
};
export default searchHelper;
