import Vue from "vue";
import Vuetify from "vuetify/lib";
import themes from "@tt/vue-components/src/theme/tt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes
  }
});
