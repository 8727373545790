import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import AttributeService from "@/services/AttributeService";

const systemOverview = {
  namespaced: true,
  state: {
    ...tableState,
    updateSystem: false,
    syncValues: false
  },
  mutations: {
    ...tableMutations,
    SET_UPDATESYSTEM(state, value) {
      state.updateSystem = value;
    },
    SET_SYNCVALUES(state, value) {
      state.syncValues = value;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        AttributeService.fetchSystems(
          context.state.itemsPage,
          context.state.itemsPerPage,
          context.state.sortByField,
          context.state.sortDesc,
          context.state.search
        )
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    searchItems(context) {
      context.dispatch("fetchItems", true);
    },
    setSyncValues(context, value) {
      // TODO update this into a request to get the values from the system
      context.commit("SET_SYNCVALUES", value);
    },
    setUpdateSystem(context, value) {
      context.commit("SET_UPDATESYSTEM", value);
    }
  },
  getters: {
    ...tableGetters
  }
};

export default systemOverview;
