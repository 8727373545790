import Vue from "vue";
import { VueAuth0 } from "@tt/vue-auth0";
import router from "@/router";
import EnvProvider from "jvjr-docker-env";

let domain = EnvProvider.value("AUTH0_DOMAIN");
let clientId = EnvProvider.value("AUTH0_CLIENT_ID");
let audience = EnvProvider.value("AUTH0_AUDIENCE");
let organization = EnvProvider.value("AUTH0_ORGANIZATION");

Vue.use(VueAuth0, {
  domain,
  clientId,
  audience,
  organization,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
