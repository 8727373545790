import ruleBuilderOverview from "@/store/modules/attributeOverview/mappingCreationOverview/ruleBuilderOverview";
import mappingInputValueOverview from "@/store/modules/attributeOverview/mappingCreationOverview/mappingInputValueOverview";
import mappingOutputValueOverview from "@/store/modules/attributeOverview/mappingCreationOverview/mappingOutputValueOverview";
import MappingService from "@/services/MappingService";

const mappingCreationOverview = {
  namespaced: true,
  modules: {
    ruleBuilderOverview,
    mappingInputValueOverview,
    mappingOutputValueOverview
  },
  state: {
    mapping: {
      id: null,
      outputValue: null,
      priority: null,
      query: null
    }
  },
  mutations: {
    SET_OUTPUTVALUE(state, value) {
      state.mapping.outputValue = value;
    },
    SET_PRIORITY(state, value) {
      state.mapping.priority = value;
    },
    SET_MAPPING(state, value) {
      state.mapping = value;
    },
    SET_QUERY(state, value) {
      state.mapping.query = value;
    }
  },
  actions: {
    search(context) {
      // set inputValueOverview attribute
      var inputValueOverviewSearch = [
        ...mappingInputValueOverview.state.search
      ];
      inputValueOverviewSearch.push({
        column: "attribute",
        value: "/attributes/" + context.rootState.attributeOverview.attribute.id
      });
      context.dispatch(
        "mappingInputValueOverview/setSearch",
        inputValueOverviewSearch
      );
      context.dispatch("mappingInputValueOverview/searchItems", true);
      context.dispatch("mappingOutputValueOverview/searchItems", true);
    },
    setOutputValue(context, value) {
      context.commit("SET_OUTPUTVALUE", value);
    },
    setPriority(context, value) {
      context.commit("SET_PRIORITY", value);
    },
    setMapping(context, value) {
      context.commit("SET_MAPPING", value);
    },
    setQuery(context, value) {
      context.commit("SET_QUERY", value);
    },
    async deleteQuery(context) {
      if (
        // needs to exist
        context.state.mapping.query != null &&
        context.state.mapping.query.id !== null
      ) {
        // may not be deleted if already has a mapping
        if (context.state.mapping.query.mapping != null) {
          //TODO rollback updates
        } else {
          await MappingService.deleteQuery(context.state.mapping.query.id);
        }

        this.clearMapping();
      }
    },
    /**
     * removes all objects in mapping, so we can create a new one
     * @param {*} context
     * @param {*} attribute may be null
     */
    clearMapping(context) {
      var newMapping = {
        id: null,
        attribute: context.rootState.attribute,
        outputValue: null,
        priority: null,
        query: null
      };
      context.commit("SET_MAPPING", newMapping);
    },
    async executeDryRun(context, inputValues) {
      console.log(JSON.stringify(ruleBuilderOverview.state.queryBuilderQuery));
      console.log(JSON.stringify(context.state.mapping));

      console.log("creating objects");
      var query = await MappingService.createQuery(
        ruleBuilderOverview.state.queryBuilderQuery,
        context.rootState.attribute.id
      );
      context.commit("SET_QUERY", query);

      console.log("creating objects done");
      console.log(JSON.stringify(query));
      console.log(JSON.stringify(inputValues));
      console.log("dry run executing");

      var result = await MappingService.executeDryRun(query, inputValues);
      console.log("dry run executed");
      console.log(JSON.stringify(result));
    },
    async addMapping(context) {
      console.log(JSON.stringify(context));
      // TODO implement
      // check if query exists in state. If so then only set mapping on query.
      // otherwise create query and add mapping
      this.clearMapping();
    }
  }
};

export default mappingCreationOverview;
