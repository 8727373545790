import Vue from "vue";
import VueQueryBuilder from "vue-query-builder";
import "vue-query-builder/dist/VueQueryBuilder.css";
import QueryBuilderGroup from "vue-query-builder/src/components/QueryBuilderGroup";
import QueryBuilderRule from "vue-query-builder/src/components/QueryBuilderRule";
import CustomQueryBuilderGroup from "@/components/queryBuilder/CustomQueryBuilderGroup";
import CustomQueryBuilderRule from "@/components/queryBuilder/CustomQueryBuilderRule";

Vue.component("vue-query-builder", VueQueryBuilder);
Vue.component("vue-query-builder-group", QueryBuilderGroup);
Vue.component("vue-query-builder-rule", QueryBuilderRule);
Vue.component("vue-query-builder-custom-group", CustomQueryBuilderGroup);
Vue.component("vue-query-builder-custom-rule", CustomQueryBuilderRule);
