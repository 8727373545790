import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import MappingService from "@/services/MappingService";
import searchHelper from "@/helpers/searchHelper";

const mappingResultOverview = {
  namespaced: true,
  state: {
    ...tableState,
    valueSearch: ""
  },
  mutations: {
    ...tableMutations,
    SET_VALUESEARCH(state, value) {
      state.valueSearch = value;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        MappingService.fetchMappingResults(
          context.state.itemsPage,
          context.state.itemsPerPage,
          context.state.sortByField,
          context.state.sortDesc,
          context.state.search
        )
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    searchItems(context) {
      let data = [...context.state.search];

      data = searchHelper.handleSearchParam(
        data,
        "and[attribute]",
        context.rootState.attributeOverview.attribute.id
      );

      data = searchHelper.handleSearchParam(
        data,
        "and[or][inputValue.content]",
        context.state.valueSearch
      );

      data = searchHelper.handleSearchParam(
        data,
        "and[or][][outputValue.content]",
        context.state.valueSearch
      );

      // fill the search again
      context.commit("EMPTY_SEARCH");
      if (Array.isArray(data) && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          context.commit("ADD_SEARCH", {
            column: data[i].column,
            value: data[i].value
          });
        }
      }

      context.dispatch("fetchItems", true);
    },
    setValueSearch(context, value) {
      context.commit("SET_VALUESEARCH", value);
    },
    async deleteMappingResult(context, mappingResult) {
      try {
        await MappingService.delete(mappingResult.mapping["@id"]);

        context.dispatch(
          "attributeOverview/inputValueOverview/fetchItems",
          true,
          { root: true }
        );
        context.dispatch("attributeOverview/attributeOv/fetchItems", true, {
          root: true
        });
        context.dispatch(
          "mappingOverview/mappingResultOverview/fetchItems",
          true,
          { root: true }
        );
      } catch (ex) {
        console.log(ex);
        throw ex["hydra:description"];
      }
    }
  },
  getters: {
    ...tableGetters
  }
};

export default mappingResultOverview;
