import outputValueOverview from "@/store/modules/attributeOverview/valueSyncOverview/outputValueOverview";
import systemOverview from "@/store/modules/attributeOverview/valueSyncOverview/systemOverview";

const valueSyncOverview = {
  namespaced: true,
  modules: {
    outputValueOverview,
    systemOverview
  },
  actions: {
    search(context) {
      var outputValueOverviewSearch = [...outputValueOverview.state.search];
      outputValueOverviewSearch.push({
        column: "attribute",
        value: "/attributes/" + context.rootState.attributeOverview.attribute.id
      });
      context.dispatch(
        "outputValueOverview/setSearch",
        outputValueOverviewSearch
      );
      context.dispatch("outputValueOverview/fetchItems", true);

      var systemOverviewSearch = [...systemOverview.state.search];
      systemOverviewSearch.push({
        column: "attribute",
        value: "/attributes/" + context.rootState.attributeOverview.attribute.id
      });
      context.dispatch("systemOverview/setSearch", systemOverviewSearch);
      context.dispatch("systemOverview/fetchItems", true);
    }
  }
};

export default valueSyncOverview;
