import BaseService from "./BaseService";
export default class AttributeService extends BaseService {
  /**
   * Abstract edit item
   * @abstract
   * @param url
   * @param item
   * @param id
   * @returns {Promise<any>}
   */
  static async syncValues(id) {
    this.initApiClientIfNotSet();
    // TODO
    // build a URL
    var url = `attributes/${id}/sync`;

    const { data } = await this.apiClient.put(url);
    return data;
  }

  /**
   * Fetch attributes
   * @param page
   * @param itemsPerPage
   * @param sortByField
   * @param sortDesc
   * @param search
   * @returns {Promise<any>}
   */
  static async fetchAttributes(
    page = 1,
    itemsPerPage = 10,
    sortByField = "",
    sortDesc = false,
    search = []
  ) {
    return this.fetchItems(
      "attributes",
      page,
      itemsPerPage,
      sortByField,
      sortDesc,
      search
    );
  }

  /**
   * Fetch attributes
   * @returns {Promise<any>}
   */
  static async fetchAttributesTotalCounts() {
    return this.fetch("attributes/total_counts");
  }

  /**
   * Fetch systems
   * @param page
   * @param itemsPerPage
   * @param sortByField
   * @param sortDesc
   * @param search
   * @returns {Promise<any>}
   */
  static async fetchSystems(
    page = 1,
    itemsPerPage = 10,
    sortByField = "",
    sortDesc = false,
    search = []
  ) {
    return this.fetchItems(
      "systems",
      page,
      itemsPerPage,
      sortByField,
      sortDesc,
      search
    );
  }

  /**
   * Add system
   * @param data
   * @returns {Promise<*>}
   */
  static async addSystem(data) {
    return this.addItem("systems", data);
  }

  /**
   * Fetch values
   * @param page
   * @param itemsPerPage
   * @param sortByField
   * @param sortDesc
   * @param search
   * @returns {Promise<any>}
   */
  static async fetchValues(
    page = 1,
    itemsPerPage = 10,
    sortByField = "",
    sortDesc = false,
    search = []
  ) {
    let values = await this.fetchItems(
      "values",
      page,
      itemsPerPage,
      sortByField,
      sortDesc,
      search
    );

    return values;
  }

  /**
   *
   * @param id
   * @returns {Promise<{}>}
   */
  static async fetchValue(id) {
    this.initApiClientIfNotSet();
    const { data } = await this.apiClient.get(id);
    return data;
  }

  /**
   * Add value
   * @param data
   * @returns {Promise<void>}
   */
  static async addValue(data) {
    return this.addItem("values", data);
  }

  /**
   * Edit value
   * @param data
   * @param id
   * @returns {Promise<void>}
   */
  static async editValue(data, id) {
    return this.editItem("values", data, id);
  }

  /**
   * Fetch mappings
   * @param page
   * @param itemsPerPage
   * @param sortByField
   * @param sortDesc
   * @param search
   * @returns {Promise<any>}
   */
  static async fetchMappings(
    page = 1,
    itemsPerPage = 10,
    sortByField = "",
    sortDesc = false,
    search = []
  ) {
    return this.fetchItems(
      "mappings",
      page,
      itemsPerPage,
      sortByField,
      sortDesc,
      search
    );
  }

  /**
   * Add mapping
   * @param data
   * @returns {Promise<void>}
   */
  static async addMapping(data) {
    return this.addItem("mappings", data);
  }
}
