import MappingService from "@/services/MappingService.js";
import inputValueOverview from "@/store/modules/attributeOverview/inputValueOverview";

const quickMappingOverview = {
  namespaced: true,
  state: {
    outputValue: null
  },
  modules: {
    inputValueOverview
  },
  mutations: {
    SET_OUTPUT_VALUE(state, outputValue) {
      state.outputValue = outputValue;
    }
  },
  actions: {
    setOutputValue(context, outputValue) {
      context.commit("SET_OUTPUT_VALUE", outputValue);
    },
    async createQuickMapping(context, outputValue) {
      try {
        let attribute = context.state.inputValueOverview.value.attribute;
        let inputValue = context.state.inputValueOverview.value;

        await MappingService.createQuickMapping({
          name: "Quick Mapping",
          attribute: attribute.name,
          inputValue: inputValue.content,
          outputValue: outputValue
        });

        context.dispatch(
          "attributeOverview/inputValueOverview/fetchItems",
          true,
          { root: true }
        );
        context.dispatch("attributeOverview/attributeOv/fetchItems", true, {
          root: true
        });
        context.dispatch(
          "mappingOverview/mappingResultOverview/fetchItems",
          true,
          { root: true }
        );
      } catch (ex) {
        if (ex["violations"]) {
          throw ex["violations"];
        } else if (ex["hydra:description"]) {
          throw ex["hydra:description"];
        } else if (ex["errors"]) {
          throw ex["errors"];
        }
      }
    }
  }
};

export default quickMappingOverview;
